.make-xs-boxstyle() {
  .clearfix();
  margin-bottom: @line-height-computed;
  padding: @grid-gutter-width;

  .bordered-headings(none);

  // Avoid breaking box styles when printing
  @media print {
    page-break-inside: avoid;
  }
}

.make-sm-boxstyle() {
  .clearfix();
  margin-bottom: @line-height-computed;

  .bordered-headings(none);

  // Avoid breaking box styles when printing
  @media print {
    page-break-inside: avoid;
  }

  @media (min-width: @screen-sm-min) {
    padding: @grid-gutter-width;
  }
}

.make-md-boxstyle() {
  .clearfix();
  margin-bottom: @line-height-computed;

  .bordered-headings(none);

  // Avoid breaking box styles when printing
  @media print {
    page-break-inside: avoid;
  }

  @media (min-width: @screen-md-min) {
    padding: @grid-gutter-width;
  }
}

.make-lg-boxstyle() {
  .clearfix();
  margin-bottom: @line-height-computed;

  .bordered-headings(none);

  // Avoid breaking box styles when printing
  @media print {
    page-break-inside: avoid;
  }

  @media (min-width: @screen-lg-min) {
    padding: @grid-gutter-width;
  }
}

#boxstyle {
  .box1-bg(@colour) {
    @result: screen(@colour, lighten(black, 85%));
  }

  .image(@class, @bg-image, @text-colour: @text-color) {
    .apply() {
      &@{class} {
        border: 0;
        background: @bg-image;
        color: @text-colour;
      }
    }

    .boxstyle-xs {
      .apply();
    }

    .boxstyle_, .boxstyle-sm {
      @media (min-width: @screen-sm-min) {
        .apply();
      }
    }

    .boxstyle-md {
      @media (min-width: @screen-md-min) {
        .apply();
      }
    }

    .boxstyle-lg {
      @media (min-width: @screen-lg-min) {
        .apply();
      }
    }
  }

  .image(@class, @bg-image, @text-colour: @text-color) when not (@text-colour = inherit) {
    .apply() {
      &@{class} {
        .header-colour(@text-colour);
        // ID-78 Hanging fire on this for now .link-colour(@text-colour);

        blockquote.quotes {
          color: inherit;
        }
      }
    }

    .boxstyle-xs {
      .apply();
    }

    .boxstyle_, .boxstyle-sm {
      @media (min-width: @screen-sm-min) {
        .apply();
      }
    }

    .boxstyle-md {
      @media (min-width: @screen-md-min) {
        .apply();
      }
    }

    .boxstyle-lg {
      @media (min-width: @screen-lg-min) {
        .apply();
      }
    }
  }

  .filled(@class, @bg, @text-colour, @link-colour) {
    // Filled boxstyles should flush at widths that the padding isn't added at
    .flush() {
      &@{class} {
        padding: @grid-gutter-width;
        margin-left: (-@grid-gutter-width);
        margin-right: (-@grid-gutter-width);
      }
    }

    .boxstyle_, .boxstyle-sm {
      @media (max-width: @screen-xs-max) {
        .flush();
      }
    }

    .boxstyle-md {
      @media (max-width: @screen-sm-max) {
        .flush();
      }
    }

    .boxstyle-lg {
      @media (max-width: @screen-md-max) {
        .flush();
      }
    }

    .boxstyle_, .boxstyle-xs, .boxstyle-sm, .boxstyle-md, .boxstyle-lg {
      &@{class} {
        border: 0;
        background: @bg;
        color: @text-colour;

        @media print {
          border: 1px solid @bg;
        }
      }
    }
  }

  .filled(@class, @bg, @text-colour, @link-colour) when not (@text-colour = inherit) {
    .boxstyle_, .boxstyle-xs, .boxstyle-sm, .boxstyle-md, .boxstyle-lg {
      &@{class} {
        .header-colour(@text-colour);

        blockquote.quotes {
          color: inherit;
        }
      }
    }
  }

  .filled(@class, @bg, @text-colour, @link-colour) when not (@link-colour = inherit) {
    .boxstyle_, .boxstyle-xs, .boxstyle-sm, .boxstyle-md, .boxstyle-lg {
      &@{class} {
        .link-colour(@text-colour);
      }
    }
  }

  .bordered(@class, @border-colour, @text-colour: inherit) {
    .apply() {
      &@{class} {
        background: none;
        border: 1px solid @border-colour;
        color: @text-colour;
      }
    }

    .boxstyle-xs {
      .apply();
    }

    .boxstyle_, .boxstyle-sm {
      @media (min-width: @screen-sm-min) {
        .apply();
      }
    }

    .boxstyle-md {
      @media (min-width: @screen-md-min) {
        .apply();
      }
    }

    .boxstyle-lg {
      @media (min-width: @screen-lg-min) {
        .apply();
      }
    }
  }

  .bordered(@class, @border-colour, @text-colour) when not (@text-colour = inherit) {
    .apply() {
      &@{class} {
        .header-colour(@text-colour);
        // ID-78 Hanging fire on this for now .link-colour(@text-colour);
      }
    }

    .boxstyle-xs {
      .apply();
    }

    .boxstyle_, .boxstyle-sm {
      @media (min-width: @screen-sm-min) {
        .apply();
      }
    }

    .boxstyle-md {
      @media (min-width: @screen-md-min) {
        .apply();
      }
    }

    .boxstyle-lg {
      @media (min-width: @screen-lg-min) {
        .apply();
      }
    }
  }
}
