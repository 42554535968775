/* A default theme */
.id7-fixed-width-container {
  background-color: #eeeeee;
}
@media (min-width: 768px) {
  .id7-left-border {
    background-color: #eeeeee;
  }
  .id7-right-border {
    background-color: #eeeeee;
  }
}
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  color: #3C1053;
}
a,
a:link,
a:visited {
  color: #3C1053;
}
a:hover,
a:focus,
a:active {
  color: #000000;
}
.popover button.close {
  color: #3C1053;
}
.popover button.close:hover {
  color: #000000;
}
a:hover .new-window-link,
a:hover .insecure-link {
  color: #000000;
}
.btn-link {
  color: #3C1053;
}
.btn-link:hover,
.btn-link:focus {
  color: #000000;
}
a,
a:link,
a:visited {
  -webkit-text-decoration-color: #775887;
  text-decoration-color: #775887;
}
a:hover,
a:focus,
a:active {
  -webkit-text-decoration-color: #000000;
  text-decoration-color: #000000;
}
blockquote.quotes {
  color: #000000;
}
blockquote.quotes::before {
  color: #000000;
}
.brand-bg {
  background: #3C1053;
  color: white;
}
.brand-bg a,
.brand-bg a:link,
.brand-bg a:visited {
  color: white !important;
}
.brand-bg a:hover,
.brand-bg a:focus,
.brand-bg a:active {
  color: #cccccc !important;
}
.brand-bg .popover button.close {
  color: white !important;
}
.brand-bg .popover button.close:hover {
  color: #cccccc !important;
}
.brand-bg a:hover .new-window-link,
.brand-bg a:hover .insecure-link {
  color: #cccccc !important;
}
.brand-bg .btn-link {
  color: white !important;
}
.brand-bg .btn-link:hover,
.brand-bg .btn-link:focus {
  color: #cccccc !important;
}
.brand-bg a,
.brand-bg a:link,
.brand-bg a:visited {
  -webkit-text-decoration-color: #ffffff !important;
  text-decoration-color: #ffffff !important;
}
.brand-bg a:hover,
.brand-bg a:focus,
.brand-bg a:active {
  -webkit-text-decoration-color: #cccccc !important;
  text-decoration-color: #cccccc !important;
}
.brand-text {
  color: #3C1053;
}
.brand-text a,
.brand-text a:link,
.brand-text a:visited {
  color: #3C1053 !important;
}
.brand-text a:hover,
.brand-text a:focus,
.brand-text a:active {
  color: #000000 !important;
}
.brand-text .popover button.close {
  color: #3C1053 !important;
}
.brand-text .popover button.close:hover {
  color: #000000 !important;
}
.brand-text a:hover .new-window-link,
.brand-text a:hover .insecure-link {
  color: #000000 !important;
}
.brand-text .btn-link {
  color: #3C1053 !important;
}
.brand-text .btn-link:hover,
.brand-text .btn-link:focus {
  color: #000000 !important;
}
.brand-text a,
.brand-text a:link,
.brand-text a:visited {
  -webkit-text-decoration-color: #775887 !important;
  text-decoration-color: #775887 !important;
}
.brand-text a:hover,
.brand-text a:focus,
.brand-text a:active {
  -webkit-text-decoration-color: #000000 !important;
  text-decoration-color: #000000 !important;
}
.brand-border {
  border-color: #3C1053;
}
.btn-brand {
  color: white;
  background-color: #3C1053;
  border-color: #2d0c3e;
  color: white !important;
}
.btn-brand:focus,
.btn-brand.focus {
  color: white;
  background-color: #1d0828;
  border-color: #000000;
}
.btn-brand:hover {
  color: white;
  background-color: #1d0828;
  border-color: #07020a;
}
.btn-brand:active,
.btn-brand.active,
.open > .dropdown-toggle.btn-brand {
  color: white;
  background-color: #1d0828;
  background-image: none;
  border-color: #07020a;
}
.btn-brand:active:hover,
.btn-brand.active:hover,
.open > .dropdown-toggle.btn-brand:hover,
.btn-brand:active:focus,
.btn-brand.active:focus,
.open > .dropdown-toggle.btn-brand:focus,
.btn-brand:active.focus,
.btn-brand.active.focus,
.open > .dropdown-toggle.btn-brand.focus {
  color: white;
  background-color: #07020a;
  border-color: #000000;
}
.btn-brand.disabled:hover,
.btn-brand[disabled]:hover,
fieldset[disabled] .btn-brand:hover,
.btn-brand.disabled:focus,
.btn-brand[disabled]:focus,
fieldset[disabled] .btn-brand:focus,
.btn-brand.disabled.focus,
.btn-brand[disabled].focus,
fieldset[disabled] .btn-brand.focus {
  background-color: #3C1053;
  border-color: #2d0c3e;
}
.btn-brand .badge {
  color: #3C1053;
  background-color: white;
}
.btn-brand:not(.btn-link) {
  text-decoration: none;
}
.id7-utility-masthead {
  background-color: #775887;
}
.id7-utility-masthead::after {
  background-color: #3C1053;
}
body.id7-point-1 .id7-utility-masthead::after {
  background-color: transparent;
}
.id7-navigation .navbar-primary {
  background-color: #3C1053;
}
.id7-navigation .navbar-primary.navbar-primary.navbar-wrapped > .navbar-nav > li {
  color: white;
}
.id7-navigation .navbar-primary.navbar-primary.navbar-wrapped > .navbar-nav > li.active,
.id7-navigation .navbar-primary.navbar-primary.navbar-wrapped > .navbar-nav > li:hover {
  color: white;
}
.id7-navigation .navbar-primary .navbar-text {
  color: white;
}
.id7-navigation .navbar-primary .navbar-nav li.dropdown ul.dropdown-menu .active::after {
  border-left-color: #775887;
}
.id7-navigation .navbar-primary .navbar-nav > li {
  color: white;
}
.id7-navigation .navbar-primary .navbar-nav > li:hover,
.id7-navigation .navbar-primary .navbar-nav > li:focus {
  color: white;
  background-color: #300d42;
}
.id7-navigation .navbar-primary .navbar-nav > li > a,
.id7-navigation .navbar-primary .navbar-nav > li > a:hover,
.id7-navigation .navbar-primary .navbar-nav > li > a:focus {
  color: inherit;
  background: none;
}
.id7-navigation .navbar-primary .navbar-nav > li.active.next-secondary::before {
  border-bottom-color: #775887;
}
.id7-navigation .navbar-primary .navbar-nav > li.active.next-tertiary::before {
  border-bottom-color: #f8f8f8;
}
.id7-navigation .navbar-primary .navbar-nav > li.active::before {
  border-bottom-color: #FFFFFF;
}
.id7-navigation .navbar-primary .navbar-nav > .disabled,
.id7-navigation .navbar-primary .navbar-nav > .disabled:hover,
.id7-navigation .navbar-primary .navbar-nav > .disabled:focus {
  color: white;
  background-color: #300d42;
}
.id7-navigation .navbar-primary .navbar-toggle {
  border: none;
  color: white;
}
.id7-navigation .navbar-primary .navbar-toggle:hover,
.id7-navigation .navbar-primary .navbar-toggle:focus {
  color: white;
  background: none;
}
.id7-navigation .navbar-primary .navbar-nav > .open,
.id7-navigation .navbar-primary .navbar-nav > .open:hover,
.id7-navigation .navbar-primary .navbar-nav > .open:focus {
  color: white;
  background-color: #300d42;
}
.id7-navigation .navbar-primary .navbar-nav .dropdown-menu {
  background-color: #3C1053;
}
.id7-navigation .navbar-primary .navbar-nav .dropdown-menu > .dropdown-header {
  border-color: #300d42;
}
.id7-navigation .navbar-primary .navbar-nav .dropdown-menu .divider {
  background-color: #300d42;
}
.id7-navigation .navbar-primary .navbar-nav .dropdown-menu > li > a {
  color: white;
}
.id7-navigation .navbar-primary .navbar-nav .dropdown-menu > li > a:hover,
.id7-navigation .navbar-primary .navbar-nav .dropdown-menu > li > a:focus {
  color: white;
  background-color: #300d42;
}
.id7-navigation .navbar-primary .navbar-nav .dropdown-menu > .active > a {
  background-color: #300d42;
  background-image: none;
}
.id7-navigation .navbar-primary .navbar-nav .dropdown-menu > .active > a::before {
  border-bottom-color: #775887;
}
.id7-navigation .navbar-primary .navbar-nav .dropdown-menu > .disabled > a,
.id7-navigation .navbar-primary .navbar-nav .dropdown-menu > .disabled > a:hover,
.id7-navigation .navbar-primary .navbar-nav .dropdown-menu > .disabled > a:focus {
  color: white;
  background-color: #300d42;
}
.id7-navigation .navbar-primary .navbar-link {
  color: white;
}
.id7-navigation .navbar-primary .navbar-link:hover {
  color: white;
}
.id7-navigation .navbar-primary .btn-link {
  color: white;
}
.id7-navigation .navbar-primary .btn-link:hover,
.id7-navigation .navbar-primary .btn-link:focus {
  color: white;
}
.id7-navigation .navbar-primary .btn-link[disabled]:hover,
fieldset[disabled] .id7-navigation .navbar-primary .btn-link:hover,
.id7-navigation .navbar-primary .btn-link[disabled]:focus,
fieldset[disabled] .id7-navigation .navbar-primary .btn-link:focus {
  color: white;
}
.navbar-primary .navbar-brand {
  background-color: #3C1053;
}
.navbar-secondary {
  background-color: #775887;
}
.navbar-secondary.navbar-primary.navbar-wrapped > .navbar-nav > li {
  color: white;
}
.navbar-secondary.navbar-primary.navbar-wrapped > .navbar-nav > li.active,
.navbar-secondary.navbar-primary.navbar-wrapped > .navbar-nav > li:hover {
  color: white;
}
.navbar-secondary .navbar-text {
  color: white;
}
.navbar-secondary .navbar-nav li.dropdown ul.dropdown-menu .active::after {
  border-left-color: white;
}
.navbar-secondary .navbar-nav > li {
  color: white;
}
.navbar-secondary .navbar-nav > li:hover,
.navbar-secondary .navbar-nav > li:focus {
  color: white;
  background-color: #5f466c;
}
.navbar-secondary .navbar-nav > li > a,
.navbar-secondary .navbar-nav > li > a:hover,
.navbar-secondary .navbar-nav > li > a:focus {
  color: inherit;
  background: none;
}
.navbar-secondary .navbar-nav > li.active.next-secondary::before {
  border-bottom-color: white;
}
.navbar-secondary .navbar-nav > li.active.next-tertiary::before {
  border-bottom-color: #f8f8f8;
}
.navbar-secondary .navbar-nav > li.active::before {
  border-bottom-color: #FFFFFF;
}
.navbar-secondary .navbar-nav > .disabled,
.navbar-secondary .navbar-nav > .disabled:hover,
.navbar-secondary .navbar-nav > .disabled:focus {
  color: white;
  background-color: #5f466c;
}
.navbar-secondary .navbar-toggle {
  border: none;
  color: white;
}
.navbar-secondary .navbar-toggle:hover,
.navbar-secondary .navbar-toggle:focus {
  color: white;
  background: none;
}
.navbar-secondary .navbar-nav > .open,
.navbar-secondary .navbar-nav > .open:hover,
.navbar-secondary .navbar-nav > .open:focus {
  color: white;
  background-color: #5f466c;
}
.navbar-secondary .navbar-nav .dropdown-menu {
  background-color: #775887;
}
.navbar-secondary .navbar-nav .dropdown-menu > .dropdown-header {
  border-color: #5f466c;
}
.navbar-secondary .navbar-nav .dropdown-menu .divider {
  background-color: #5f466c;
}
.navbar-secondary .navbar-nav .dropdown-menu > li > a {
  color: white;
}
.navbar-secondary .navbar-nav .dropdown-menu > li > a:hover,
.navbar-secondary .navbar-nav .dropdown-menu > li > a:focus {
  color: white;
  background-color: #5f466c;
}
.navbar-secondary .navbar-nav .dropdown-menu > .active > a {
  background-color: #5f466c;
  background-image: none;
}
.navbar-secondary .navbar-nav .dropdown-menu > .active > a::before {
  border-bottom-color: white;
}
.navbar-secondary .navbar-nav .dropdown-menu > .disabled > a,
.navbar-secondary .navbar-nav .dropdown-menu > .disabled > a:hover,
.navbar-secondary .navbar-nav .dropdown-menu > .disabled > a:focus {
  color: white;
  background-color: #5f466c;
}
.navbar-secondary .navbar-link {
  color: white;
}
.navbar-secondary .navbar-link:hover {
  color: white;
}
.navbar-secondary .btn-link {
  color: white;
}
.navbar-secondary .btn-link:hover,
.navbar-secondary .btn-link:focus {
  color: white;
}
.navbar-secondary .btn-link[disabled]:hover,
fieldset[disabled] .navbar-secondary .btn-link:hover,
.navbar-secondary .btn-link[disabled]:focus,
fieldset[disabled] .navbar-secondary .btn-link:focus {
  color: white;
}
.navbar-secondary .navbar-nav > li::after {
  color: #3C1053;
}
.navbar-secondary .navbar-nav > li:first-child {
  background-color: #3C1053;
  color: white;
}
.navbar-secondary .navbar-nav > li:first-child:hover::after {
  border-right-color: #3C1053;
}
.navbar-secondary .navbar-nav > li:first-child::after {
  border-color: transparent transparent transparent #3C1053;
}
.navbar-tertiary {
  background-color: #f8f8f8;
}
.navbar-tertiary.navbar-primary.navbar-wrapped > .navbar-nav > li {
  color: #202020;
}
.navbar-tertiary.navbar-primary.navbar-wrapped > .navbar-nav > li.active,
.navbar-tertiary.navbar-primary.navbar-wrapped > .navbar-nav > li:hover {
  color: #202020;
}
.navbar-tertiary .navbar-text {
  color: #202020;
}
.navbar-tertiary .navbar-nav li.dropdown ul.dropdown-menu .active::after {
  border-left-color: white;
}
.navbar-tertiary .navbar-nav > li {
  color: #202020;
}
.navbar-tertiary .navbar-nav > li:hover,
.navbar-tertiary .navbar-nav > li:focus {
  color: #202020;
  background-color: #c6c6c6;
}
.navbar-tertiary .navbar-nav > li > a,
.navbar-tertiary .navbar-nav > li > a:hover,
.navbar-tertiary .navbar-nav > li > a:focus {
  color: inherit;
  background: none;
}
.navbar-tertiary .navbar-nav > li.active.next-secondary::before {
  border-bottom-color: white;
}
.navbar-tertiary .navbar-nav > li.active.next-tertiary::before {
  border-bottom-color: #f8f8f8;
}
.navbar-tertiary .navbar-nav > li.active::before {
  border-bottom-color: #FFFFFF;
}
.navbar-tertiary .navbar-nav > .disabled,
.navbar-tertiary .navbar-nav > .disabled:hover,
.navbar-tertiary .navbar-nav > .disabled:focus {
  color: #202020;
  background-color: #c6c6c6;
}
.navbar-tertiary .navbar-toggle {
  border: none;
  color: #202020;
}
.navbar-tertiary .navbar-toggle:hover,
.navbar-tertiary .navbar-toggle:focus {
  color: #202020;
  background: none;
}
.navbar-tertiary .navbar-nav > .open,
.navbar-tertiary .navbar-nav > .open:hover,
.navbar-tertiary .navbar-nav > .open:focus {
  color: #202020;
  background-color: #c6c6c6;
}
.navbar-tertiary .navbar-nav .dropdown-menu {
  background-color: #f8f8f8;
}
.navbar-tertiary .navbar-nav .dropdown-menu > .dropdown-header {
  border-color: #c6c6c6;
}
.navbar-tertiary .navbar-nav .dropdown-menu .divider {
  background-color: #c6c6c6;
}
.navbar-tertiary .navbar-nav .dropdown-menu > li > a {
  color: #202020;
}
.navbar-tertiary .navbar-nav .dropdown-menu > li > a:hover,
.navbar-tertiary .navbar-nav .dropdown-menu > li > a:focus {
  color: #202020;
  background-color: #c6c6c6;
}
.navbar-tertiary .navbar-nav .dropdown-menu > .active > a {
  background-color: #c6c6c6;
  background-image: none;
}
.navbar-tertiary .navbar-nav .dropdown-menu > .active > a::before {
  border-bottom-color: white;
}
.navbar-tertiary .navbar-nav .dropdown-menu > .disabled > a,
.navbar-tertiary .navbar-nav .dropdown-menu > .disabled > a:hover,
.navbar-tertiary .navbar-nav .dropdown-menu > .disabled > a:focus {
  color: #202020;
  background-color: #c6c6c6;
}
.navbar-tertiary .navbar-link {
  color: #202020;
}
.navbar-tertiary .navbar-link:hover {
  color: #202020;
}
.navbar-tertiary .btn-link {
  color: #202020;
}
.navbar-tertiary .btn-link:hover,
.navbar-tertiary .btn-link:focus {
  color: #202020;
}
.navbar-tertiary .btn-link[disabled]:hover,
fieldset[disabled] .navbar-tertiary .btn-link:hover,
.navbar-tertiary .btn-link[disabled]:focus,
fieldset[disabled] .navbar-tertiary .btn-link:focus {
  color: #202020;
}
.bordered .id7-left-border,
.bordered .id7-right-border {
  border-color: rgba(60, 16, 83, 0.3);
}
.id7-page-footer .id7-app-footer {
  background: #3C1053;
  color: white;
}
.id7-page-footer .id7-app-footer h1,
.id7-page-footer .id7-app-footer h2,
.id7-page-footer .id7-app-footer h3,
.id7-page-footer .id7-app-footer h4,
.id7-page-footer .id7-app-footer h5,
.id7-page-footer .id7-app-footer h6,
.id7-page-footer .id7-app-footer .h1,
.id7-page-footer .id7-app-footer .h2,
.id7-page-footer .id7-app-footer .h3,
.id7-page-footer .id7-app-footer .h4,
.id7-page-footer .id7-app-footer .h5,
.id7-page-footer .id7-app-footer .h6 {
  color: white;
}
.id7-page-footer .id7-app-footer a,
.id7-page-footer .id7-app-footer a:link,
.id7-page-footer .id7-app-footer a:visited {
  color: white;
}
.id7-page-footer .id7-app-footer a:hover,
.id7-page-footer .id7-app-footer a:focus,
.id7-page-footer .id7-app-footer a:active {
  color: #cccccc;
}
.id7-page-footer .id7-app-footer .popover button.close {
  color: white;
}
.id7-page-footer .id7-app-footer .popover button.close:hover {
  color: #cccccc;
}
.id7-page-footer .id7-app-footer a:hover .new-window-link,
.id7-page-footer .id7-app-footer a:hover .insecure-link {
  color: #cccccc;
}
.id7-page-footer .id7-app-footer .btn-link {
  color: white;
}
.id7-page-footer .id7-app-footer .btn-link:hover,
.id7-page-footer .id7-app-footer .btn-link:focus {
  color: #cccccc;
}
.id7-page-footer .id7-app-footer a,
.id7-page-footer .id7-app-footer a:link,
.id7-page-footer .id7-app-footer a:visited {
  -webkit-text-decoration-color: #ffffff;
  text-decoration-color: #ffffff;
}
.id7-page-footer .id7-app-footer a:hover,
.id7-page-footer .id7-app-footer a:focus,
.id7-page-footer .id7-app-footer a:active {
  -webkit-text-decoration-color: #cccccc;
  text-decoration-color: #cccccc;
}
.id7-page-footer .id7-horizontal-divider .divider {
  stroke: #3C1053;
}
.id7-page-footer.id7-footer-divider .id7-site-footer {
  background: white;
}
.id7-page-footer.id7-footer-divider .id7-app-footer .id7-logo-bleed::before,
.id7-page-footer.id7-footer-divider .id7-app-footer .id7-logo-bleed::after {
  border-top-color: white;
}
.id7-page-footer.id7-footer-coloured .id7-site-footer {
  background: #d3d3d3;
  color: #202020;
}
.id7-page-footer.id7-footer-coloured .id7-site-footer h1,
.id7-page-footer.id7-footer-coloured .id7-site-footer h2,
.id7-page-footer.id7-footer-coloured .id7-site-footer h3,
.id7-page-footer.id7-footer-coloured .id7-site-footer h4,
.id7-page-footer.id7-footer-coloured .id7-site-footer h5,
.id7-page-footer.id7-footer-coloured .id7-site-footer h6,
.id7-page-footer.id7-footer-coloured .id7-site-footer .h1,
.id7-page-footer.id7-footer-coloured .id7-site-footer .h2,
.id7-page-footer.id7-footer-coloured .id7-site-footer .h3,
.id7-page-footer.id7-footer-coloured .id7-site-footer .h4,
.id7-page-footer.id7-footer-coloured .id7-site-footer .h5,
.id7-page-footer.id7-footer-coloured .id7-site-footer .h6 {
  color: #202020;
}
.id7-page-footer.id7-footer-coloured .id7-site-footer a,
.id7-page-footer.id7-footer-coloured .id7-site-footer a:link,
.id7-page-footer.id7-footer-coloured .id7-site-footer a:visited {
  color: #202020;
}
.id7-page-footer.id7-footer-coloured .id7-site-footer a:hover,
.id7-page-footer.id7-footer-coloured .id7-site-footer a:focus,
.id7-page-footer.id7-footer-coloured .id7-site-footer a:active {
  color: #000000;
}
.id7-page-footer.id7-footer-coloured .id7-site-footer .popover button.close {
  color: #202020;
}
.id7-page-footer.id7-footer-coloured .id7-site-footer .popover button.close:hover {
  color: #000000;
}
.id7-page-footer.id7-footer-coloured .id7-site-footer a:hover .new-window-link,
.id7-page-footer.id7-footer-coloured .id7-site-footer a:hover .insecure-link {
  color: #000000;
}
.id7-page-footer.id7-footer-coloured .id7-site-footer .btn-link {
  color: #202020;
}
.id7-page-footer.id7-footer-coloured .id7-site-footer .btn-link:hover,
.id7-page-footer.id7-footer-coloured .id7-site-footer .btn-link:focus {
  color: #000000;
}
.id7-page-footer.id7-footer-coloured .id7-site-footer a,
.id7-page-footer.id7-footer-coloured .id7-site-footer a:link,
.id7-page-footer.id7-footer-coloured .id7-site-footer a:visited {
  -webkit-text-decoration-color: #636363;
  text-decoration-color: #636363;
}
.id7-page-footer.id7-footer-coloured .id7-site-footer a:hover,
.id7-page-footer.id7-footer-coloured .id7-site-footer a:focus,
.id7-page-footer.id7-footer-coloured .id7-site-footer a:active {
  -webkit-text-decoration-color: #000000;
  text-decoration-color: #000000;
}
.id7-page-footer.id7-footer-coloured .id7-app-footer .id7-logo-bleed::before,
.id7-page-footer.id7-footer-coloured .id7-app-footer .id7-logo-bleed::after {
  border-top-color: #d3d3d3;
}
.id7-search .fa,
.id7-search .fas {
  color: #3C1053;
  color: rgba(60, 16, 83, 0.5);
  -webkit-transition: color 0.15s linear;
  -o-transition: color 0.15s linear;
  transition: color 0.15s linear;
}
.id7-search .form-control:hover + .fa,
.id7-search .typeahead:hover + .fa,
.id7-search .fa:hover,
.id7-search .form-control:hover + .fas,
.id7-search .typeahead:hover + .fas,
.id7-search .fas:hover {
  color: #3C1053;
}
.id7-search .typeahead.dropdown-menu > li.active > a {
  background: #3C1053;
  color: white;
}
.form-control:focus {
  border-color: #3C1053;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(60, 16, 83, 0.6);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(60, 16, 83, 0.6);
}
.carousel .carousel-indicators li::after {
  color: #3C1053;
  color: rgba(60, 16, 83, 0.3);
}
.carousel .carousel-indicators li.active::after {
  color: #3C1053;
}
@media (max-width: 767px) {
  .boxstyle_.box1,
  .boxstyle-sm.box1 {
    padding: 20px;
    margin-left: -20px;
    margin-right: -20px;
  }
}
@media (max-width: 991px) {
  .boxstyle-md.box1 {
    padding: 20px;
    margin-left: -20px;
    margin-right: -20px;
  }
}
@media (max-width: 1199px) {
  .boxstyle-lg.box1 {
    padding: 20px;
    margin-left: -20px;
    margin-right: -20px;
  }
}
.boxstyle_.box1,
.boxstyle-xs.box1,
.boxstyle-sm.box1,
.boxstyle-md.box1,
.boxstyle-lg.box1 {
  border: 0;
  background: #e2dbe5;
  color: #202020;
}
@media print {
  .boxstyle_.box1,
  .boxstyle-xs.box1,
  .boxstyle-sm.box1,
  .boxstyle-md.box1,
  .boxstyle-lg.box1 {
    border: 1px solid #e2dbe5;
  }
}
.boxstyle_.box1 h1,
.boxstyle-xs.box1 h1,
.boxstyle-sm.box1 h1,
.boxstyle-md.box1 h1,
.boxstyle-lg.box1 h1,
.boxstyle_.box1 h2,
.boxstyle-xs.box1 h2,
.boxstyle-sm.box1 h2,
.boxstyle-md.box1 h2,
.boxstyle-lg.box1 h2,
.boxstyle_.box1 h3,
.boxstyle-xs.box1 h3,
.boxstyle-sm.box1 h3,
.boxstyle-md.box1 h3,
.boxstyle-lg.box1 h3,
.boxstyle_.box1 h4,
.boxstyle-xs.box1 h4,
.boxstyle-sm.box1 h4,
.boxstyle-md.box1 h4,
.boxstyle-lg.box1 h4,
.boxstyle_.box1 h5,
.boxstyle-xs.box1 h5,
.boxstyle-sm.box1 h5,
.boxstyle-md.box1 h5,
.boxstyle-lg.box1 h5,
.boxstyle_.box1 h6,
.boxstyle-xs.box1 h6,
.boxstyle-sm.box1 h6,
.boxstyle-md.box1 h6,
.boxstyle-lg.box1 h6,
.boxstyle_.box1 .h1,
.boxstyle-xs.box1 .h1,
.boxstyle-sm.box1 .h1,
.boxstyle-md.box1 .h1,
.boxstyle-lg.box1 .h1,
.boxstyle_.box1 .h2,
.boxstyle-xs.box1 .h2,
.boxstyle-sm.box1 .h2,
.boxstyle-md.box1 .h2,
.boxstyle-lg.box1 .h2,
.boxstyle_.box1 .h3,
.boxstyle-xs.box1 .h3,
.boxstyle-sm.box1 .h3,
.boxstyle-md.box1 .h3,
.boxstyle-lg.box1 .h3,
.boxstyle_.box1 .h4,
.boxstyle-xs.box1 .h4,
.boxstyle-sm.box1 .h4,
.boxstyle-md.box1 .h4,
.boxstyle-lg.box1 .h4,
.boxstyle_.box1 .h5,
.boxstyle-xs.box1 .h5,
.boxstyle-sm.box1 .h5,
.boxstyle-md.box1 .h5,
.boxstyle-lg.box1 .h5,
.boxstyle_.box1 .h6,
.boxstyle-xs.box1 .h6,
.boxstyle-sm.box1 .h6,
.boxstyle-md.box1 .h6,
.boxstyle-lg.box1 .h6 {
  color: #202020;
}
.boxstyle_.box1 blockquote.quotes,
.boxstyle-xs.box1 blockquote.quotes,
.boxstyle-sm.box1 blockquote.quotes,
.boxstyle-md.box1 blockquote.quotes,
.boxstyle-lg.box1 blockquote.quotes {
  color: inherit;
}
.boxstyle_.box1 a,
.boxstyle-xs.box1 a,
.boxstyle-sm.box1 a,
.boxstyle-md.box1 a,
.boxstyle-lg.box1 a,
.boxstyle_.box1 a:link,
.boxstyle-xs.box1 a:link,
.boxstyle-sm.box1 a:link,
.boxstyle-md.box1 a:link,
.boxstyle-lg.box1 a:link,
.boxstyle_.box1 a:visited,
.boxstyle-xs.box1 a:visited,
.boxstyle-sm.box1 a:visited,
.boxstyle-md.box1 a:visited,
.boxstyle-lg.box1 a:visited {
  color: #202020;
}
.boxstyle_.box1 a:hover,
.boxstyle-xs.box1 a:hover,
.boxstyle-sm.box1 a:hover,
.boxstyle-md.box1 a:hover,
.boxstyle-lg.box1 a:hover,
.boxstyle_.box1 a:focus,
.boxstyle-xs.box1 a:focus,
.boxstyle-sm.box1 a:focus,
.boxstyle-md.box1 a:focus,
.boxstyle-lg.box1 a:focus,
.boxstyle_.box1 a:active,
.boxstyle-xs.box1 a:active,
.boxstyle-sm.box1 a:active,
.boxstyle-md.box1 a:active,
.boxstyle-lg.box1 a:active {
  color: #000000;
}
.boxstyle_.box1 .popover button.close,
.boxstyle-xs.box1 .popover button.close,
.boxstyle-sm.box1 .popover button.close,
.boxstyle-md.box1 .popover button.close,
.boxstyle-lg.box1 .popover button.close {
  color: #202020;
}
.boxstyle_.box1 .popover button.close:hover,
.boxstyle-xs.box1 .popover button.close:hover,
.boxstyle-sm.box1 .popover button.close:hover,
.boxstyle-md.box1 .popover button.close:hover,
.boxstyle-lg.box1 .popover button.close:hover {
  color: #000000;
}
.boxstyle_.box1 a:hover .new-window-link,
.boxstyle-xs.box1 a:hover .new-window-link,
.boxstyle-sm.box1 a:hover .new-window-link,
.boxstyle-md.box1 a:hover .new-window-link,
.boxstyle-lg.box1 a:hover .new-window-link,
.boxstyle_.box1 a:hover .insecure-link,
.boxstyle-xs.box1 a:hover .insecure-link,
.boxstyle-sm.box1 a:hover .insecure-link,
.boxstyle-md.box1 a:hover .insecure-link,
.boxstyle-lg.box1 a:hover .insecure-link {
  color: #000000;
}
.boxstyle_.box1 .btn-link,
.boxstyle-xs.box1 .btn-link,
.boxstyle-sm.box1 .btn-link,
.boxstyle-md.box1 .btn-link,
.boxstyle-lg.box1 .btn-link {
  color: #202020;
}
.boxstyle_.box1 .btn-link:hover,
.boxstyle-xs.box1 .btn-link:hover,
.boxstyle-sm.box1 .btn-link:hover,
.boxstyle-md.box1 .btn-link:hover,
.boxstyle-lg.box1 .btn-link:hover,
.boxstyle_.box1 .btn-link:focus,
.boxstyle-xs.box1 .btn-link:focus,
.boxstyle-sm.box1 .btn-link:focus,
.boxstyle-md.box1 .btn-link:focus,
.boxstyle-lg.box1 .btn-link:focus {
  color: #000000;
}
.boxstyle_.box1 a,
.boxstyle-xs.box1 a,
.boxstyle-sm.box1 a,
.boxstyle-md.box1 a,
.boxstyle-lg.box1 a,
.boxstyle_.box1 a:link,
.boxstyle-xs.box1 a:link,
.boxstyle-sm.box1 a:link,
.boxstyle-md.box1 a:link,
.boxstyle-lg.box1 a:link,
.boxstyle_.box1 a:visited,
.boxstyle-xs.box1 a:visited,
.boxstyle-sm.box1 a:visited,
.boxstyle-md.box1 a:visited,
.boxstyle-lg.box1 a:visited {
  -webkit-text-decoration-color: #636363;
  text-decoration-color: #636363;
}
.boxstyle_.box1 a:hover,
.boxstyle-xs.box1 a:hover,
.boxstyle-sm.box1 a:hover,
.boxstyle-md.box1 a:hover,
.boxstyle-lg.box1 a:hover,
.boxstyle_.box1 a:focus,
.boxstyle-xs.box1 a:focus,
.boxstyle-sm.box1 a:focus,
.boxstyle-md.box1 a:focus,
.boxstyle-lg.box1 a:focus,
.boxstyle_.box1 a:active,
.boxstyle-xs.box1 a:active,
.boxstyle-sm.box1 a:active,
.boxstyle-md.box1 a:active,
.boxstyle-lg.box1 a:active {
  -webkit-text-decoration-color: #000000;
  text-decoration-color: #000000;
}
@media (max-width: 767px) {
  .boxstyle_.box2,
  .boxstyle-sm.box2 {
    padding: 20px;
    margin-left: -20px;
    margin-right: -20px;
  }
}
@media (max-width: 991px) {
  .boxstyle-md.box2 {
    padding: 20px;
    margin-left: -20px;
    margin-right: -20px;
  }
}
@media (max-width: 1199px) {
  .boxstyle-lg.box2 {
    padding: 20px;
    margin-left: -20px;
    margin-right: -20px;
  }
}
.boxstyle_.box2,
.boxstyle-xs.box2,
.boxstyle-sm.box2,
.boxstyle-md.box2,
.boxstyle-lg.box2 {
  border: 0;
  background: #eeeeee;
  color: #202020;
}
@media print {
  .boxstyle_.box2,
  .boxstyle-xs.box2,
  .boxstyle-sm.box2,
  .boxstyle-md.box2,
  .boxstyle-lg.box2 {
    border: 1px solid #eeeeee;
  }
}
.boxstyle_.box2 h1,
.boxstyle-xs.box2 h1,
.boxstyle-sm.box2 h1,
.boxstyle-md.box2 h1,
.boxstyle-lg.box2 h1,
.boxstyle_.box2 h2,
.boxstyle-xs.box2 h2,
.boxstyle-sm.box2 h2,
.boxstyle-md.box2 h2,
.boxstyle-lg.box2 h2,
.boxstyle_.box2 h3,
.boxstyle-xs.box2 h3,
.boxstyle-sm.box2 h3,
.boxstyle-md.box2 h3,
.boxstyle-lg.box2 h3,
.boxstyle_.box2 h4,
.boxstyle-xs.box2 h4,
.boxstyle-sm.box2 h4,
.boxstyle-md.box2 h4,
.boxstyle-lg.box2 h4,
.boxstyle_.box2 h5,
.boxstyle-xs.box2 h5,
.boxstyle-sm.box2 h5,
.boxstyle-md.box2 h5,
.boxstyle-lg.box2 h5,
.boxstyle_.box2 h6,
.boxstyle-xs.box2 h6,
.boxstyle-sm.box2 h6,
.boxstyle-md.box2 h6,
.boxstyle-lg.box2 h6,
.boxstyle_.box2 .h1,
.boxstyle-xs.box2 .h1,
.boxstyle-sm.box2 .h1,
.boxstyle-md.box2 .h1,
.boxstyle-lg.box2 .h1,
.boxstyle_.box2 .h2,
.boxstyle-xs.box2 .h2,
.boxstyle-sm.box2 .h2,
.boxstyle-md.box2 .h2,
.boxstyle-lg.box2 .h2,
.boxstyle_.box2 .h3,
.boxstyle-xs.box2 .h3,
.boxstyle-sm.box2 .h3,
.boxstyle-md.box2 .h3,
.boxstyle-lg.box2 .h3,
.boxstyle_.box2 .h4,
.boxstyle-xs.box2 .h4,
.boxstyle-sm.box2 .h4,
.boxstyle-md.box2 .h4,
.boxstyle-lg.box2 .h4,
.boxstyle_.box2 .h5,
.boxstyle-xs.box2 .h5,
.boxstyle-sm.box2 .h5,
.boxstyle-md.box2 .h5,
.boxstyle-lg.box2 .h5,
.boxstyle_.box2 .h6,
.boxstyle-xs.box2 .h6,
.boxstyle-sm.box2 .h6,
.boxstyle-md.box2 .h6,
.boxstyle-lg.box2 .h6 {
  color: #202020;
}
.boxstyle_.box2 blockquote.quotes,
.boxstyle-xs.box2 blockquote.quotes,
.boxstyle-sm.box2 blockquote.quotes,
.boxstyle-md.box2 blockquote.quotes,
.boxstyle-lg.box2 blockquote.quotes {
  color: inherit;
}
.boxstyle_.box2 a,
.boxstyle-xs.box2 a,
.boxstyle-sm.box2 a,
.boxstyle-md.box2 a,
.boxstyle-lg.box2 a,
.boxstyle_.box2 a:link,
.boxstyle-xs.box2 a:link,
.boxstyle-sm.box2 a:link,
.boxstyle-md.box2 a:link,
.boxstyle-lg.box2 a:link,
.boxstyle_.box2 a:visited,
.boxstyle-xs.box2 a:visited,
.boxstyle-sm.box2 a:visited,
.boxstyle-md.box2 a:visited,
.boxstyle-lg.box2 a:visited {
  color: #202020;
}
.boxstyle_.box2 a:hover,
.boxstyle-xs.box2 a:hover,
.boxstyle-sm.box2 a:hover,
.boxstyle-md.box2 a:hover,
.boxstyle-lg.box2 a:hover,
.boxstyle_.box2 a:focus,
.boxstyle-xs.box2 a:focus,
.boxstyle-sm.box2 a:focus,
.boxstyle-md.box2 a:focus,
.boxstyle-lg.box2 a:focus,
.boxstyle_.box2 a:active,
.boxstyle-xs.box2 a:active,
.boxstyle-sm.box2 a:active,
.boxstyle-md.box2 a:active,
.boxstyle-lg.box2 a:active {
  color: #000000;
}
.boxstyle_.box2 .popover button.close,
.boxstyle-xs.box2 .popover button.close,
.boxstyle-sm.box2 .popover button.close,
.boxstyle-md.box2 .popover button.close,
.boxstyle-lg.box2 .popover button.close {
  color: #202020;
}
.boxstyle_.box2 .popover button.close:hover,
.boxstyle-xs.box2 .popover button.close:hover,
.boxstyle-sm.box2 .popover button.close:hover,
.boxstyle-md.box2 .popover button.close:hover,
.boxstyle-lg.box2 .popover button.close:hover {
  color: #000000;
}
.boxstyle_.box2 a:hover .new-window-link,
.boxstyle-xs.box2 a:hover .new-window-link,
.boxstyle-sm.box2 a:hover .new-window-link,
.boxstyle-md.box2 a:hover .new-window-link,
.boxstyle-lg.box2 a:hover .new-window-link,
.boxstyle_.box2 a:hover .insecure-link,
.boxstyle-xs.box2 a:hover .insecure-link,
.boxstyle-sm.box2 a:hover .insecure-link,
.boxstyle-md.box2 a:hover .insecure-link,
.boxstyle-lg.box2 a:hover .insecure-link {
  color: #000000;
}
.boxstyle_.box2 .btn-link,
.boxstyle-xs.box2 .btn-link,
.boxstyle-sm.box2 .btn-link,
.boxstyle-md.box2 .btn-link,
.boxstyle-lg.box2 .btn-link {
  color: #202020;
}
.boxstyle_.box2 .btn-link:hover,
.boxstyle-xs.box2 .btn-link:hover,
.boxstyle-sm.box2 .btn-link:hover,
.boxstyle-md.box2 .btn-link:hover,
.boxstyle-lg.box2 .btn-link:hover,
.boxstyle_.box2 .btn-link:focus,
.boxstyle-xs.box2 .btn-link:focus,
.boxstyle-sm.box2 .btn-link:focus,
.boxstyle-md.box2 .btn-link:focus,
.boxstyle-lg.box2 .btn-link:focus {
  color: #000000;
}
.boxstyle_.box2 a,
.boxstyle-xs.box2 a,
.boxstyle-sm.box2 a,
.boxstyle-md.box2 a,
.boxstyle-lg.box2 a,
.boxstyle_.box2 a:link,
.boxstyle-xs.box2 a:link,
.boxstyle-sm.box2 a:link,
.boxstyle-md.box2 a:link,
.boxstyle-lg.box2 a:link,
.boxstyle_.box2 a:visited,
.boxstyle-xs.box2 a:visited,
.boxstyle-sm.box2 a:visited,
.boxstyle-md.box2 a:visited,
.boxstyle-lg.box2 a:visited {
  -webkit-text-decoration-color: #636363;
  text-decoration-color: #636363;
}
.boxstyle_.box2 a:hover,
.boxstyle-xs.box2 a:hover,
.boxstyle-sm.box2 a:hover,
.boxstyle-md.box2 a:hover,
.boxstyle-lg.box2 a:hover,
.boxstyle_.box2 a:focus,
.boxstyle-xs.box2 a:focus,
.boxstyle-sm.box2 a:focus,
.boxstyle-md.box2 a:focus,
.boxstyle-lg.box2 a:focus,
.boxstyle_.box2 a:active,
.boxstyle-xs.box2 a:active,
.boxstyle-sm.box2 a:active,
.boxstyle-md.box2 a:active,
.boxstyle-lg.box2 a:active {
  -webkit-text-decoration-color: #000000;
  text-decoration-color: #000000;
}
.boxstyle-xs.box3 {
  background: none;
  border: 1px solid #3C1053;
  color: inherit;
}
@media (min-width: 768px) {
  .boxstyle_.box3,
  .boxstyle-sm.box3 {
    background: none;
    border: 1px solid #3C1053;
    color: inherit;
  }
}
@media (min-width: 992px) {
  .boxstyle-md.box3 {
    background: none;
    border: 1px solid #3C1053;
    color: inherit;
  }
}
@media (min-width: 1200px) {
  .boxstyle-lg.box3 {
    background: none;
    border: 1px solid #3C1053;
    color: inherit;
  }
}
.boxstyle-xs.box4 {
  background: none;
  border: 1px solid #777777;
  color: inherit;
}
@media (min-width: 768px) {
  .boxstyle_.box4,
  .boxstyle-sm.box4 {
    background: none;
    border: 1px solid #777777;
    color: inherit;
  }
}
@media (min-width: 992px) {
  .boxstyle-md.box4 {
    background: none;
    border: 1px solid #777777;
    color: inherit;
  }
}
@media (min-width: 1200px) {
  .boxstyle-lg.box4 {
    background: none;
    border: 1px solid #777777;
    color: inherit;
  }
}
@media (max-width: 767px) {
  .boxstyle_.box5,
  .boxstyle-sm.box5 {
    padding: 20px;
    margin-left: -20px;
    margin-right: -20px;
  }
}
@media (max-width: 991px) {
  .boxstyle-md.box5 {
    padding: 20px;
    margin-left: -20px;
    margin-right: -20px;
  }
}
@media (max-width: 1199px) {
  .boxstyle-lg.box5 {
    padding: 20px;
    margin-left: -20px;
    margin-right: -20px;
  }
}
.boxstyle_.box5,
.boxstyle-xs.box5,
.boxstyle-sm.box5,
.boxstyle-md.box5,
.boxstyle-lg.box5 {
  border: 0;
  background: #3C1053;
  color: white;
}
@media print {
  .boxstyle_.box5,
  .boxstyle-xs.box5,
  .boxstyle-sm.box5,
  .boxstyle-md.box5,
  .boxstyle-lg.box5 {
    border: 1px solid #3C1053;
  }
}
.boxstyle_.box5 h1,
.boxstyle-xs.box5 h1,
.boxstyle-sm.box5 h1,
.boxstyle-md.box5 h1,
.boxstyle-lg.box5 h1,
.boxstyle_.box5 h2,
.boxstyle-xs.box5 h2,
.boxstyle-sm.box5 h2,
.boxstyle-md.box5 h2,
.boxstyle-lg.box5 h2,
.boxstyle_.box5 h3,
.boxstyle-xs.box5 h3,
.boxstyle-sm.box5 h3,
.boxstyle-md.box5 h3,
.boxstyle-lg.box5 h3,
.boxstyle_.box5 h4,
.boxstyle-xs.box5 h4,
.boxstyle-sm.box5 h4,
.boxstyle-md.box5 h4,
.boxstyle-lg.box5 h4,
.boxstyle_.box5 h5,
.boxstyle-xs.box5 h5,
.boxstyle-sm.box5 h5,
.boxstyle-md.box5 h5,
.boxstyle-lg.box5 h5,
.boxstyle_.box5 h6,
.boxstyle-xs.box5 h6,
.boxstyle-sm.box5 h6,
.boxstyle-md.box5 h6,
.boxstyle-lg.box5 h6,
.boxstyle_.box5 .h1,
.boxstyle-xs.box5 .h1,
.boxstyle-sm.box5 .h1,
.boxstyle-md.box5 .h1,
.boxstyle-lg.box5 .h1,
.boxstyle_.box5 .h2,
.boxstyle-xs.box5 .h2,
.boxstyle-sm.box5 .h2,
.boxstyle-md.box5 .h2,
.boxstyle-lg.box5 .h2,
.boxstyle_.box5 .h3,
.boxstyle-xs.box5 .h3,
.boxstyle-sm.box5 .h3,
.boxstyle-md.box5 .h3,
.boxstyle-lg.box5 .h3,
.boxstyle_.box5 .h4,
.boxstyle-xs.box5 .h4,
.boxstyle-sm.box5 .h4,
.boxstyle-md.box5 .h4,
.boxstyle-lg.box5 .h4,
.boxstyle_.box5 .h5,
.boxstyle-xs.box5 .h5,
.boxstyle-sm.box5 .h5,
.boxstyle-md.box5 .h5,
.boxstyle-lg.box5 .h5,
.boxstyle_.box5 .h6,
.boxstyle-xs.box5 .h6,
.boxstyle-sm.box5 .h6,
.boxstyle-md.box5 .h6,
.boxstyle-lg.box5 .h6 {
  color: white;
}
.boxstyle_.box5 blockquote.quotes,
.boxstyle-xs.box5 blockquote.quotes,
.boxstyle-sm.box5 blockquote.quotes,
.boxstyle-md.box5 blockquote.quotes,
.boxstyle-lg.box5 blockquote.quotes {
  color: inherit;
}
.boxstyle_.box5 a,
.boxstyle-xs.box5 a,
.boxstyle-sm.box5 a,
.boxstyle-md.box5 a,
.boxstyle-lg.box5 a,
.boxstyle_.box5 a:link,
.boxstyle-xs.box5 a:link,
.boxstyle-sm.box5 a:link,
.boxstyle-md.box5 a:link,
.boxstyle-lg.box5 a:link,
.boxstyle_.box5 a:visited,
.boxstyle-xs.box5 a:visited,
.boxstyle-sm.box5 a:visited,
.boxstyle-md.box5 a:visited,
.boxstyle-lg.box5 a:visited {
  color: white;
}
.boxstyle_.box5 a:hover,
.boxstyle-xs.box5 a:hover,
.boxstyle-sm.box5 a:hover,
.boxstyle-md.box5 a:hover,
.boxstyle-lg.box5 a:hover,
.boxstyle_.box5 a:focus,
.boxstyle-xs.box5 a:focus,
.boxstyle-sm.box5 a:focus,
.boxstyle-md.box5 a:focus,
.boxstyle-lg.box5 a:focus,
.boxstyle_.box5 a:active,
.boxstyle-xs.box5 a:active,
.boxstyle-sm.box5 a:active,
.boxstyle-md.box5 a:active,
.boxstyle-lg.box5 a:active {
  color: #cccccc;
}
.boxstyle_.box5 .popover button.close,
.boxstyle-xs.box5 .popover button.close,
.boxstyle-sm.box5 .popover button.close,
.boxstyle-md.box5 .popover button.close,
.boxstyle-lg.box5 .popover button.close {
  color: white;
}
.boxstyle_.box5 .popover button.close:hover,
.boxstyle-xs.box5 .popover button.close:hover,
.boxstyle-sm.box5 .popover button.close:hover,
.boxstyle-md.box5 .popover button.close:hover,
.boxstyle-lg.box5 .popover button.close:hover {
  color: #cccccc;
}
.boxstyle_.box5 a:hover .new-window-link,
.boxstyle-xs.box5 a:hover .new-window-link,
.boxstyle-sm.box5 a:hover .new-window-link,
.boxstyle-md.box5 a:hover .new-window-link,
.boxstyle-lg.box5 a:hover .new-window-link,
.boxstyle_.box5 a:hover .insecure-link,
.boxstyle-xs.box5 a:hover .insecure-link,
.boxstyle-sm.box5 a:hover .insecure-link,
.boxstyle-md.box5 a:hover .insecure-link,
.boxstyle-lg.box5 a:hover .insecure-link {
  color: #cccccc;
}
.boxstyle_.box5 .btn-link,
.boxstyle-xs.box5 .btn-link,
.boxstyle-sm.box5 .btn-link,
.boxstyle-md.box5 .btn-link,
.boxstyle-lg.box5 .btn-link {
  color: white;
}
.boxstyle_.box5 .btn-link:hover,
.boxstyle-xs.box5 .btn-link:hover,
.boxstyle-sm.box5 .btn-link:hover,
.boxstyle-md.box5 .btn-link:hover,
.boxstyle-lg.box5 .btn-link:hover,
.boxstyle_.box5 .btn-link:focus,
.boxstyle-xs.box5 .btn-link:focus,
.boxstyle-sm.box5 .btn-link:focus,
.boxstyle-md.box5 .btn-link:focus,
.boxstyle-lg.box5 .btn-link:focus {
  color: #cccccc;
}
.boxstyle_.box5 a,
.boxstyle-xs.box5 a,
.boxstyle-sm.box5 a,
.boxstyle-md.box5 a,
.boxstyle-lg.box5 a,
.boxstyle_.box5 a:link,
.boxstyle-xs.box5 a:link,
.boxstyle-sm.box5 a:link,
.boxstyle-md.box5 a:link,
.boxstyle-lg.box5 a:link,
.boxstyle_.box5 a:visited,
.boxstyle-xs.box5 a:visited,
.boxstyle-sm.box5 a:visited,
.boxstyle-md.box5 a:visited,
.boxstyle-lg.box5 a:visited {
  -webkit-text-decoration-color: #ffffff;
  text-decoration-color: #ffffff;
}
.boxstyle_.box5 a:hover,
.boxstyle-xs.box5 a:hover,
.boxstyle-sm.box5 a:hover,
.boxstyle-md.box5 a:hover,
.boxstyle-lg.box5 a:hover,
.boxstyle_.box5 a:focus,
.boxstyle-xs.box5 a:focus,
.boxstyle-sm.box5 a:focus,
.boxstyle-md.box5 a:focus,
.boxstyle-lg.box5 a:focus,
.boxstyle_.box5 a:active,
.boxstyle-xs.box5 a:active,
.boxstyle-sm.box5 a:active,
.boxstyle-md.box5 a:active,
.boxstyle-lg.box5 a:active {
  -webkit-text-decoration-color: #cccccc;
  text-decoration-color: #cccccc;
}
.pagination > li a,
.pagination > li span {
  color: #3C1053;
}
.pagination > li.active > a,
.pagination > li.active > span:not(.sr-only),
.pagination > li.active > a:hover,
.pagination > li.active > span:not(.sr-only):hover,
.pagination > li.active > a:focus,
.pagination > li.active > span:not(.sr-only):focus {
  background-color: #3C1053;
  border-color: #3C1053;
  color: white;
}
.nav-tabs {
  border-bottom-color: #9e88a9;
}
.nav-tabs > li > a {
  background-color: #d8cfdd;
  border-color: #9e88a9;
  transition: background 0.3s cubic-bezier(0.44, 0, 0.35, 1);
}
.nav-tabs > li > a::before {
  display: block;
  position: absolute;
  content: '';
  width: 0;
  height: 3px;
  background: #8a7098;
  top: 0;
  left: 0;
  transition: width 0.3s cubic-bezier(0.44, 0, 0.35, 1);
}
.nav-tabs > li > a:hover,
.nav-tabs > li > a:focus {
  background-color: #ece7ee;
  border-color: #b19fba #b19fba #9e88a9;
}
.nav-tabs > li > a:hover::before,
.nav-tabs > li > a:focus::before {
  width: 100%;
}
.nav-tabs > li.open > a,
.nav-tabs > li.open > a:hover,
.nav-tabs > li.open > a:focus {
  background-color: #ece7ee;
  border-color: #b19fba #b19fba #9e88a9;
}
.nav-tabs > li.open > a::before,
.nav-tabs > li.open > a:hover::before,
.nav-tabs > li.open > a:focus::before {
  background: #3C1053;
  width: 100%;
}
.nav-tabs > li.active > a,
.nav-tabs > li.active > a:hover,
.nav-tabs > li.active > a:focus {
  border-color: #9e88a9 #9e88a9 transparent;
}
.nav-tabs > li.active > a::before,
.nav-tabs > li.active > a:hover::before,
.nav-tabs > li.active > a:focus::before {
  background: #3C1053;
  width: 100%;
}
.nav-tabs > li.disabled > a {
  color: #555;
}
.nav-tabs > li.disabled > a:hover,
.nav-tabs > li.disabled > a:focus {
  background-color: #d8cfdd;
}
.nav-tabs > li.disabled > a::before {
  background: transparent;
}
.nav-pills > li.active > a,
.nav-pills > li.active > a:hover,
.nav-pills > li.active > a:focus {
  background-color: #3C1053;
  color: white;
}
.list-group-item.active,
.list-group-item.active:hover,
.list-group-item.active:focus {
  background-color: #3C1053;
  border-color: #3C1053;
  color: white;
}
.id7-koan-spinner__neon--top,
.id7-koan-spinner__neon--bottom {
  stroke: #3C1053;
}
