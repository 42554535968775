@import (reference) '../bootstrap/less/variables.less';
@import (reference) '../bootstrap/less/mixins.less';
@import (reference) 'variables.less';
@import (reference) 'mixins.less';

/* A default theme */
.id7-fixed-width-container {
  background-color: @gray-lighter;
}

@media (min-width: @grid-float-breakpoint) {
  .id7-left-border {
    background-color: @gray-lighter;
  }

  .id7-right-border {
    background-color: @gray-lighter;
  }
}

.apply-brand(@id7-brand-default);