.link-colour(@base, @hover) {
  @c: @base;

  a, a:link, a:visited {
    color: @c;
  }

  a:hover, a:focus, a:active {
    color: @hover;
  }

  .popover button.close {
    color: @c;
    &:hover {
      color: @hover;
    }
  }

  a {
    &:hover {
      .new-window-link, .insecure-link {
        color: @hover;
      }
    }
  }

  .btn-link {
    color: @c;
    &:hover,
    &:focus {
      color: @hover;
    }
  }
}

.link-colour(@base, @hover) when not (@base = inherit) {
  a, a:link, a:visited {
    -webkit-text-decoration-color: screen(@base, lighten(black, 30%)); // Bug in caniuse/autoprefixer
    text-decoration-color: screen(@base, lighten(black, 30%));
  }

  a:hover, a:focus, a:active {
    -webkit-text-decoration-color: @hover; // Bug in caniuse/autoprefixer
    text-decoration-color: @hover;
  }
}

.link-colour(@base) when not (@base = inherit) {
  .link-colour(@base, darken(@base, 20%));
}

.link-colour(@base) when (@base = inherit) {
  a, a:link, a:visited {
    color: inherit;
  }

  a:hover, a:focus, a:active {
    color: inherit;
  }

  a {
    .new-window-link, .insecure-link {
      color: inherit;
    }

    &:hover {
      .new-window-link, .insecure-link {
        color: inherit;
      }
    }
  }
}

.link-decoration(@base: none, @hover: underline) {
  a, a:link, a:visited {
    text-decoration: @base;
  }

  a:hover, a:focus, a:active {
    text-decoration: @hover;
  }
}
